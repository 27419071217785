// This file is used to group all the reducers and effects of the application.

// reducers
import { appReducers } from '@state/app.reducers';
import { airportsReducers } from '@features/settings/airports/state/airports.reducers';
import { schedulesReducers } from '@features/schedules/state/schedules.reducers';

// effects
import { AppEffects } from '@state/app.effects';
import { AirportsEffects } from '@features/settings/airports/state/airports.effects';
import { SchedulesEffects } from '@features/schedules/state/schedules.effects';
import { AirlineOperatingCertificatesEffects } from '@features/settings/airline-operating-certificate/state/airline-operating-certificate.effects';
import { airlineOperatingCertificatesReducers } from '@features/settings/airline-operating-certificate/state/airline-operating-certificate.reducers';
import { aircraftsReducers } from '@features/settings/aircrafts/state/aircrafts.reducers';
import { AircraftsEffects } from '@features/settings/aircrafts/state/aircrafts.effects';
import { AuthEffects } from '@state/auth.effects';

export const appReducersGroup = {
  app: appReducers,
  airports: airportsReducers,
  schedules: schedulesReducers,
  airlineOperatingCertificates: airlineOperatingCertificatesReducers,
  aircrafts: aircraftsReducers
};

export const appEffectsGroup = [
  AppEffects,
  AuthEffects,
  SchedulesEffects,
  AirportsEffects,
  AirlineOperatingCertificatesEffects,
  AircraftsEffects
];
