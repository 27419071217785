import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { selectSeason } from '@state/app.selectors';
import { map, switchMap, tap } from 'rxjs/operators';
import { AircraftsHttpService } from '@features/settings/aircrafts/aircrafts.http.service';
import { userIsAuthenticated, seasonChangedInSelector } from '@state/app.actions';
import {
  addAircraftButtonClicked,
  aircarftsAPIFetchingSuccess,
  aircraftsAPIFetchingFailed,
  apiAddAircraftFailed,
  apiAddAircraftSuccess,
  apiDeleteAircraftFailed,
  apiDeleteAircraftSuccess,
  apiUpdateAircraftFailed,
  apiUpdateAircraftSuccess,
  deleteAircraftButtonClicked,
  updateAircraftButtonClicked
} from '@features/settings/aircrafts/state/aircrafts.actions';
import { catchError, of } from 'rxjs';

@Injectable()
export class AircraftsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private toastrService: ToastrService,
    private aircraftsHttpService: AircraftsHttpService
  ) {}

  fetchAircraftsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(userIsAuthenticated, seasonChangedInSelector),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([, season]) => {
        return this.aircraftsHttpService.getFleet(season);
      }),
      map((aircrafts) => aircarftsAPIFetchingSuccess({ aircrafts })),
      catchError(() => of(aircraftsAPIFetchingFailed))
    );
  });

  addAircraftEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(addAircraftButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, season]) => {
        return this.aircraftsHttpService.addAircraft(season, action?.aircraft);
      }),
      tap(() => this.toastrService.success('Aircraft added successfully.')),
      map((aircraft) => apiAddAircraftSuccess({ aircraft })),
      catchError(() => of(apiAddAircraftFailed))
    );
  });

  updateAircraftEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAircraftButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, season]) => {
        return this.aircraftsHttpService.updateAircraft(season, action?.aircraft);
      }),
      tap(() => this.toastrService.success('Aircraft updated successfully.')),
      map((aircraft) => apiUpdateAircraftSuccess({ aircraft })),
      catchError(() => of(apiUpdateAircraftFailed))
    );
  });

  deleteAircraftEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteAircraftButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([{ aircraftId }, season]) => {
        return this.aircraftsHttpService.deleteAircraft(season, aircraftId).pipe(
          tap(() => this.toastrService.success('Aircraft deleted successfully')),
          map(() => apiDeleteAircraftSuccess({ aircraftId })),
          catchError(() => of(apiDeleteAircraftFailed))
        );
      })
    );
  });
}
