import { Observable, timer } from 'rxjs';
import { retry } from 'rxjs/operators';

const DEFAULT_CONFIG: BackoffConfig = { count: 3, delay: 2000 };
export type BackoffConfig = { count: number; delay: number };

export function backoffRetry<T>(config: BackoffConfig = DEFAULT_CONFIG): (obs: Observable<T>) => Observable<T> {
  return (obs$: Observable<T>) =>
    obs$.pipe(
      retry({
        count: config.count,
        delay: (_, retryIndex) => {
          return timer(Math.pow(2, retryIndex - 1) * config.delay);
        }
      })
    );
}
