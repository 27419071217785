import {
  FlowFilterQuery,
  OptimiserResultDto,
  OptimiserStatus
} from '@features/schedules/flying-lines/flying-lines.model';
import { FlyingLinesViewConfig } from '@shared/components/flyinglines/flyinglines.models';
import { FilterOperator } from '@shared/models/filter.model';
import { SectorAssociatedSlots } from '@features/historic-slots/historic-slots.models';
import { ScheduleSettingsCap } from '@features/schedules/schedule-settings/schedule-settings.models';
import { ScheduleInfoOptimiserObjective } from '../schedules.models';

export interface SchedulesState {
  optimiserResult: OptimiserResultDto;
  optimiserStatus: OptimiserStatus;
  optimiserStarted: string | undefined;
  dropdownFilterOptions: string[];
  dropdownFilterOperator: FilterOperator;
  flyingLinesViewConfig: FlyingLinesViewConfig;
  allAssociatedSectorSlots: SectorAssociatedSlots;
  flyingLinesFilters: FlowFilterQuery[] | undefined;
  isLoading: boolean;
  scheduledDays: number[];
  scheduleSettingsCap: ScheduleSettingsCap[];
  scheduleInfoOptimiserObjective: ScheduleInfoOptimiserObjective[];
}

export const initialFlyingLinesViewConfig: FlyingLinesViewConfig = {
  zoom: 1.0,
  showStats: true,
  showCrewLines: true,
  showAllOvernightGroundTimes: true,
  showOnlyFrozenLines: false,
  colorScheme: 'markets',
  timeIn: 'LocalTime',
  showTriangleSectors: false,
  showWsSectors: false,
  daysSectorAverage: {},
  isUpdateLinesEnabled: true,
  isUpdateSectorsEnabled: true,
  isEditable: false,
  showTooltips: true,
  utcOffset: null,
  dates: []
};

export const schedulesInitialState: SchedulesState = {
  optimiserResult: undefined,
  optimiserStatus: 'UNKNOWN',
  optimiserStarted: undefined,
  dropdownFilterOptions: undefined,
  dropdownFilterOperator: 'AND',
  flyingLinesViewConfig: initialFlyingLinesViewConfig,
  flyingLinesFilters: undefined,
  allAssociatedSectorSlots: {
    departure_slots: undefined,
    arrival_slots: undefined
  },
  isLoading: undefined,
  scheduledDays: [],
  scheduleSettingsCap: [],
  scheduleInfoOptimiserObjective: []
};
