import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import {
  accessTokenIsRestored,
  accessTokenNotFoundInStorage,
  apiGetUserDataSuccess,
  apiLoginError,
  apiLoginSuccess,
  apiRefreshedTokenSuccess,
  apiRefreshTokenError,
  userIsAuthenticated,
  badCredentialsToastShown,
  layoutComponentLogoutActionClicked,
  loginScreenUserCredentialInput,
  refreshToken,
  tokensRemovedInAnotherAppInStore,
  topRightMenuActionsLogoutSuccess,
  userloggedOutSuccess,
  appStarted
} from './app.actions';
import { Store } from '@ngrx/store';
import { selectInternetStatus } from '@state/app.selectors';
import { ToastrService } from 'ngx-toastr';
import { AuthHttpService } from '@authorization/services/auth-http.service';
import { Router } from '@angular/router';
import { StorageRecordName, StorageService } from '@shared/services/storage/storage.service';
import { isAccessTokenValid, isRefreshTokenValid } from '@utils/functions/token-validation.fun';
import { EMPTY, forkJoin, of } from 'rxjs';
import { ModalService } from '@shared/components/dialogs/modal.service';
import { LogService } from '@shared/services/log.service';
import { backoffRetry } from '@shared/helpers/backoff-operator';
import { AuthorizationTokensDto } from '@authorization/services/auth-token.models';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private authHttpService: AuthHttpService,
    private storageService: StorageService,
    private router: Router,
    private toastrService: ToastrService,
    private modalService: ModalService
  ) {}

  appStartedEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(appStarted),
      concatMap(() => this.storageService.get<AuthorizationTokensDto>(StorageRecordName.UserAccessTokens)),
      map((loginResponseTokens) => {
        if (loginResponseTokens) {
          return accessTokenIsRestored({ loginResponseTokens });
        } else {
          return accessTokenNotFoundInStorage();
        }
      })
    );
  });

  userAuthenticatedStartedEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(apiLoginSuccess, accessTokenIsRestored),
      map(() => userIsAuthenticated())
    );
  });

  loginEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginScreenUserCredentialInput),
      switchMap((action) => {
        return this.authHttpService.login(action.loginRequestDto).pipe(
          map((loginResponseTokens) => {
            this.storageService.set(StorageRecordName.UserAccessTokensRefreshStarted, false).subscribe();
            this.storageService.set(StorageRecordName.UserAccessTokens, loginResponseTokens).subscribe();
            LogService.log(
              'info',
              '[Login effect] updated token',
              loginResponseTokens.access_token.slice(-5),
              loginResponseTokens.refresh_token.slice(-5)
            );
            return apiLoginSuccess();
          }),
          catchError(() => {
            return of(apiLoginError());
          })
        );
      })
    );
  });

  loadUserDataEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(apiLoginSuccess, accessTokenIsRestored),
      switchMap((action) => forkJoin([this.authHttpService.loadUser(), of(action)])),
      tap(([, action]) => {
        if (action.type === apiLoginSuccess.type) {
          this.router.navigate(['/schedules']);
        }
      }),
      tap(([user]) =>
        user?.features
          ? LogService.log(
              'info',
              `User ${user.support_user ? '(support)' : ''} loaded with features: ${user?.features?.join(', ')}`
            )
          : LogService.log('warning', 'User without features payload')
      ),
      map(([user]) => apiGetUserDataSuccess({ user }))
    );
  });

  wrongCredentialsError = createEffect(() => {
    return this.actions$.pipe(
      ofType(apiLoginError),
      tap(() => {
        this.toastrService.error('You have entered incorrect username or password. Please try again...');
      }),
      map(() => badCredentialsToastShown())
    );
  });

  refreshTokenEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshToken),
      concatMap((action) => {
        return forkJoin([
          of(action),
          this.storageService.get<boolean>(StorageRecordName.UserAccessTokensRefreshStarted)
        ]).pipe(
          tap(([, refreshAlreadyStarted]) => {
            if (refreshAlreadyStarted) {
              LogService.log(
                'info',
                `[Refresh effect:${action.requestId}] Other refresh is happening, waiting for it to finish`
              );
              throw Error('Refresh already started');
            }
          }),
          backoffRetry()
        );
      }),
      exhaustMap(([action]) => {
        if (isAccessTokenValid(action.authenticationResult)) {
          LogService.log('info', `[Refresh effect:${action.requestId}] Access token is valid, not refreshing`);
          return EMPTY;
        }

        if (!isRefreshTokenValid(action.authenticationResult)) {
          LogService.log('info', `[Refresh effect:${action.requestId}] Refresh token is not valid, finishing`);
          return of(tokensRemovedInAnotherAppInStore());
        }

        this.storageService.set(StorageRecordName.UserAccessTokensRefreshStarted, true).subscribe();
        LogService.log(
          'info',
          `[Refresh effect:${action.requestId}] Refresh token is valid, sending refresh request with refresh token:`,
          action.authenticationResult.refresh_token.slice(-5)
        );
        return this.storageService
          .get<AuthorizationTokensDto>(StorageRecordName.UserAccessTokens)
          .pipe(
            switchMap((accessTokens) => this.authHttpService.refreshToken(accessTokens)),
            backoffRetry({ count: 3, delay: 1000 })
          )
          .pipe(
            map((newAuthenticationResult) => {
              this.storageService.set(StorageRecordName.UserAccessTokens, newAuthenticationResult).subscribe();
              LogService.log(
                'info',
                `[Refresh effect:${action.requestId}] Received new token`,
                'Replacing access token with:',
                newAuthenticationResult.access_token.slice(-5),
                'Refresh token with:',
                newAuthenticationResult.refresh_token.slice(-5)
              );
              this.storageService.set(StorageRecordName.UserAccessTokensRefreshStarted, false).subscribe();
              return apiRefreshedTokenSuccess({ loginResponseTokens: newAuthenticationResult });
            }),
            catchError(() => {
              this.storageService.set(StorageRecordName.UserAccessTokensRefreshStarted, false).subscribe();
              return of(apiRefreshTokenError());
            })
          );
      })
    );
  });

  appLogoutEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(layoutComponentLogoutActionClicked),
      concatMap(() => this.storageService.get<AuthorizationTokensDto>(StorageRecordName.UserAccessTokens)),
      tap((user) => this.authHttpService.resetToken(user).subscribe()),
      map(() => topRightMenuActionsLogoutSuccess())
    );
  });

  logoutUserEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        topRightMenuActionsLogoutSuccess,
        apiRefreshTokenError,
        tokensRemovedInAnotherAppInStore,
        accessTokenNotFoundInStorage
      ),
      tap(() => this.modalService.hideLoader()),
      concatLatestFrom(() => this.store.select(selectInternetStatus)),
      concatMap(([action, connectionStatus]) => {
        if (action.type === apiRefreshTokenError.type && connectionStatus === 'disconnected') {
          return EMPTY;
        }
        return this.storageService.delete(StorageRecordName.UserAccessTokens);
      }),
      tap(() => {
        setTimeout(() => {
          this.router.navigate(['/sign-in']);
        }, 100);
      }),
      map(() => userloggedOutSuccess())
    );
  });
}
