import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ModalService } from '@shared/components/dialogs/modal.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { StorageRecordName, StorageService } from '@shared/services/storage/storage.service';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private storageService: StorageService,
    private modalService: ModalService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleError(error);
        this.hideLoader();
        return throwError(error);
      }),
      // Listen for internet connection status
      catchError((error: HttpErrorResponse) => {
        if (!navigator.onLine) {
          this.handleError(new HttpErrorResponse({ status: 0 }));
        }
        return throwError(error);
      })
    );
  }

  private showMessage(messageOrMessages: string | string[], defaultMessage?: string): void {
    const toastrService: ToastrService = this.injector.get(ToastrService);
    const title = 'Something went wrong';
    if (!messageOrMessages || (Array.isArray(messageOrMessages) && !messageOrMessages.length)) {
      toastrService.error(defaultMessage || 'Please try again later.', title, { closeButton: true });
    } else if (Array.isArray(messageOrMessages)) {
      toastrService.error(messageOrMessages[0], title, { closeButton: true });
    } else {
      toastrService.error(messageOrMessages || defaultMessage || 'Please try again later.', title, {
        closeButton: true
      });
    }
  }

  private showSuccessMessage(message: string): void {
    const toastrService: ToastrService = this.injector.get(ToastrService);
    toastrService.success(message, 'Success', { closeButton: true });
  }

  private hideLoader(): void {
    const modalService: ModalService = this.injector.get(ModalService);
    modalService.hideLoader();
  }

  private handleBlobError(blobError: Blob): void {
    fromPromise(blobError.text())
      .pipe(map((text) => JSON.parse(text)))
      .subscribe((errorResponse) => {
        const messages: string[] | undefined = errorResponse.apierror?.messages;
        this.showMessage(messages);
      });
  }

  private handleError(error: HttpErrorResponse): void {
    this.modalService.hideLoader();
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    if (error.error instanceof Blob) {
      this.handleBlobError(error.error);
      return;
    }
    const messages: string[] | undefined = error.error?.apierror?.messages;
    switch (error.status) {
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.PreconditionFailed:
        this.showMessage(messages, 'Validation error. Please check data .');
        break;
      case HttpStatusCode.Forbidden:
        this.showMessage('You are not authorized to perform this action.');
        this.storageService.delete(StorageRecordName.UserAccessTokens).subscribe(() => {
          this.router.navigate(['/sign-in']);
        });
        break;
      case HttpStatusCode.NotFound:
        this.showMessage(messages, 'Requested resource not found.');
        break;
      case HttpStatusCode.InternalServerError:
        this.showMessage('An application error has occurred');
        break;
      case HttpStatusCode.GatewayTimeout:
        //eslint-disable-next-line no-console
        console.warn(`Request to ${error.url} timed out`);
        break;
      case 0:
        if (error.error instanceof ProgressEvent) {
          this.showMessage('We are having problems connecting to our server. Please try again');
        }
        break;
      default:
        break;
    }
  }
}
