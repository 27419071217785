import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AirlineOperatingCertificate } from './airline-operating-certificate.model';
import { HttpClient } from '@angular/common/http';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class AirlineOperatingCertificateHttpService {
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getAirlineOperatingCertificate(season: SeasonCode): Observable<AirlineOperatingCertificate[]> {
    return this.http.get<AirlineOperatingCertificate[]>(`${this.apiUrl}/${season}/aoc`);
  }

  addAirlineOperatingCertificate(
    season: SeasonCode,
    aoc: AirlineOperatingCertificate
  ): Observable<AirlineOperatingCertificate> {
    return this.http.post<AirlineOperatingCertificate>(`${this.apiUrl}/${season}/aoc`, aoc);
  }

  updateAirlineOperatingCertificate(
    season: SeasonCode,
    aoc: AirlineOperatingCertificate,
    id: number
  ): Observable<AirlineOperatingCertificate> {
    return this.http.put<AirlineOperatingCertificate>(`${this.apiUrl}/${season}/aoc/${id}`, aoc);
  }

  removeAirlineOperatingCertificate(season: SeasonCode, id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${season}/aoc/${id}`);
  }
}
