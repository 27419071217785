import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AirlineOperatingCertificateHttpService } from '@features/settings/airline-operating-certificate/airline-operating-certificate-http.service';
import { userIsAuthenticated, seasonChangedInSelector } from '@state/app.actions';
import { selectSeason } from '@state/app.selectors';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  addAOCButtonClicked,
  aocAPIFetchingSuccess,
  updateAOCButtonClicked,
  aocAPIFetchingFailed,
  addingNewAOCSuccess,
  addingNewAOCFailed,
  updatingAOCSuccess,
  updatingAOCFailed,
  removingAOCSuccess,
  removingAOCFailed,
  removeAOCButtonClicked
} from '@features/settings/airline-operating-certificate/state/airline-operating-certificate.actions';
import { catchError, of } from 'rxjs';

@Injectable()
export class AirlineOperatingCertificatesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private toastrService: ToastrService,
    private airlineOperatingCertificateHttpService: AirlineOperatingCertificateHttpService
  ) {}

  fetchAOCEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(userIsAuthenticated, seasonChangedInSelector),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([, season]) => {
        return this.airlineOperatingCertificateHttpService.getAirlineOperatingCertificate(season);
      }),
      map((aocs) => aocAPIFetchingSuccess({ aocs })),
      catchError(() => of(aocAPIFetchingFailed))
    );
  });

  addAOCEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(addAOCButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, season]) => {
        const AOC = { ...action.aoc };
        AOC.season = season;
        return this.airlineOperatingCertificateHttpService.addAirlineOperatingCertificate(season, AOC);
      }),
      tap(() => this.toastrService.success('Airline operating certificate added successfully.')),
      map((aoc) => addingNewAOCSuccess({ aoc })),
      catchError(() => of(addingNewAOCFailed))
    );
  });

  updateAOCEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAOCButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, season]) => {
        return this.airlineOperatingCertificateHttpService.updateAirlineOperatingCertificate(
          season,
          action.aoc,
          action.aoc.id
        );
      }),
      tap(() => this.toastrService.success('Airline operating certificate updated successfully.')),
      map((aoc) => updatingAOCSuccess({ aoc })),
      catchError(() => of(updatingAOCFailed))
    );
  });

  removeAOCEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(removeAOCButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([{ id }, season]) => {
        return this.airlineOperatingCertificateHttpService.removeAirlineOperatingCertificate(season, id).pipe(
          tap(() => this.toastrService.success('Airline operating certificate removed successfully')),
          map(() => removingAOCSuccess({ id })),
          catchError(() => of(removingAOCFailed))
        );
      })
    );
  });
}
