import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { environment } from '@environments/environment';
import { AuthTokenInterceptor } from '@shared/interceptors/auth-token/auth-token.interceptor';
import { HttpErrorsInterceptor } from '@shared/interceptors/http-errors/http-errors.interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { MergedRouterStateSerializer } from '@state/router.selector';
import { ServiceWorkerModule } from '@angular/service-worker';
import { appReducersGroup, appEffectsGroup } from './app-state.config';
import { metaReducers } from '@state/app.reducers';
import { AppInitService } from './app-init.service';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    Sentry.TraceModule,
    ToastrModule.forRoot({ preventDuplicates: true, autoDismiss: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({ ...appReducersGroup, router: routerReducer }, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 40, logOnly: environment.production, connectInZone: true }),
    StoreRouterConnectingModule.forRoot({ serializer: MergedRouterStateSerializer }),
    EffectsModule.forRoot(appEffectsGroup),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.name !== 'local',
      registrationStrategy: 'registerWhenStable:10000'
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService, AppInitService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}

// required for AOT compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
