import { Airport, AirportOption, AirportsSettings } from '@features/settings/airports/airports.model';

export interface AirportsState {
  list: Airport[] | null;
  listOptions: AirportOption[] | null;
  settings: AirportsSettings;
}

export const initialAirportsState: AirportsState = {
  list: null,
  listOptions: null,
  settings: {
    selectedAirports: [],
    columnConfig: undefined
  }
};
