import { createReducer, on } from '@ngrx/store';
import {
  initialFlyingLinesViewConfig,
  schedulesInitialState,
  SchedulesState
} from '@features/schedules/state/schedules.state';
import {
  allAssociatedSectorSlotsAPIFetchSuccess,
  apiBulkFreezeLineSuccess,
  apiBulkUnfreezeLineSuccess,
  apiClearOptimiserSuccess,
  apiFetchOptimiserResultFailed,
  apiFetchOptimiserResultSuccess,
  apiFreezeLineSuccess,
  apiLockSectorSuccess,
  apiScheduleSettingsSectorCapsAddedSuccess,
  apiScheduleSettingsSectorCapsUpdatedSuccess,
  apiSchedulesReceivedStatusUpdate,
  apiStartOptimiserErrorsReturned,
  apiStopOptimiserSuccess,
  apiUnfreezeLineSuccess,
  flyingLinesApplyFiltersButtonClicked,
  flyingLinesColorSchemeButtonClicked,
  flyingLinesPageClosed,
  flyingLinesPageInitializedSuccess,
  flyingLinesPageQueryParamsChanged,
  flyingLinesPageQueryParamsChangedRequiredChanges,
  flyingLinesShowAllOvernightGroundTimesButtonClicked,
  flyingLinesShowCrewLinesButtonClicked,
  flyingLinesShowOnlyFrozenLinesButtonClicked,
  flyingLinesTimeInButtonClicked,
  flyingLinesUTCOffsetButtonClicked,
  flyingLinesZoomButtonClicked,
  scheduledCellDaysAPIFetchingSuccess,
  scheduleDetailsButtonResumeOptimiserClicked,
  scheduleDetailsButtonStartOptimiserClicked,
  scheduleSettingsCapAPIFetchingSuccess,
  scheduleSettingsSectorCapsDeleted,
  schedulesListPageLoaded,
  shouldPollConditionPassSuccess,
  updateAssociatedSectorSlotsSuccess,
  utilisationPerWeekdayCalculated
} from '@features/schedules/state/schedules.actions';
import {
  invertTimeLocale,
  OptimiserResultLineDto,
  OptimiserSectorDto
} from '@features/schedules/flying-lines/flying-lines.model';
import {
  scheduleDetailsPageDestroyed,
  scheduleFetchedOnScheduleDetailsPage,
  scheduleFetchedOptimiserObjectiveSuccess,
  userloggedOutSuccess
} from '@state/app.actions';
import { AssociatedSlot } from '@features/historic-slots/historic-slots.models';

export const schedulesReducers = createReducer<SchedulesState>(
  schedulesInitialState,
  on(
    apiFetchOptimiserResultSuccess,
    (state, action): SchedulesState => ({
      ...state,
      optimiserResult: action.optimiserResult
    })
  ),
  on(schedulesListPageLoaded, (): SchedulesState => schedulesInitialState),
  on(
    shouldPollConditionPassSuccess,
    apiFetchOptimiserResultFailed,
    apiFetchOptimiserResultSuccess,
    (state, action): SchedulesState => {
      return {
        ...state,
        isLoading: action.type === shouldPollConditionPassSuccess.type
      };
    }
  ),
  on(
    flyingLinesPageInitializedSuccess,
    (state, action): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        showStats: action.showStats ?? state.flyingLinesViewConfig.showStats,
        showCrewLines: action.showCrewLines ?? state.flyingLinesViewConfig.showCrewLines,
        showWsSectors: action.showWsSectors ?? state.flyingLinesViewConfig.showWsSectors,
        showTriangleSectors: action.showTriangleSectors ?? state.flyingLinesViewConfig.showTriangleSectors,
        showAllOvernightGroundTimes:
          action.showAllOvernightGroundTimes ?? state.flyingLinesViewConfig.showAllOvernightGroundTimes,
        showOnlyFrozenLines: action.showOnlyFrozenLines ?? state.flyingLinesViewConfig.showOnlyFrozenLines,
        timeIn: action.timeIn ?? state.flyingLinesViewConfig.timeIn,
        colorScheme: action.colorScheme ?? state.flyingLinesViewConfig.colorScheme
      },
      dropdownFilterOptions: action.dropdownFilterOptions ?? state.dropdownFilterOptions,
      dropdownFilterOperator: action.dropdownFilterOperator ?? state.dropdownFilterOperator,
      flyingLinesFilters: action.flyingLinesFilters ?? state.flyingLinesFilters
    })
  ),
  on(
    flyingLinesPageQueryParamsChanged,
    flyingLinesPageQueryParamsChangedRequiredChanges,
    (state, action): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        showStats: action.queryParams.showStats ?? state.flyingLinesViewConfig.showStats,
        showCrewLines: action.queryParams.showCrewLines ?? state.flyingLinesViewConfig.showCrewLines,
        showWsSectors: action.queryParams.showWsSectors ?? state.flyingLinesViewConfig.showWsSectors,
        showTriangleSectors: action.queryParams.showTriangleSectors ?? state.flyingLinesViewConfig.showTriangleSectors,
        showAllOvernightGroundTimes:
          action.queryParams.showAllOvernightGroundTimes ?? state.flyingLinesViewConfig.showAllOvernightGroundTimes,
        showOnlyFrozenLines: action.queryParams.showOnlyFrozenLines ?? state.flyingLinesViewConfig.showOnlyFrozenLines,
        timeIn: action.queryParams.timeIn ?? state.flyingLinesViewConfig.timeIn,
        colorScheme: action.queryParams.colorScheme ?? state.flyingLinesViewConfig.colorScheme
      },
      dropdownFilterOptions: action.queryParams.dropdownFilterOptions ?? state.dropdownFilterOptions,
      dropdownFilterOperator: action.queryParams.dropdownFilterOperator ?? state.dropdownFilterOperator,
      flyingLinesFilters:
        action.type === flyingLinesPageQueryParamsChangedRequiredChanges.type ? undefined : state.flyingLinesFilters
    })
  ),
  on(utilisationPerWeekdayCalculated, (state, action): SchedulesState => {
    return {
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        daysSectorAverage: action.daysSectorAverage
      }
    };
  }),
  on(scheduleDetailsPageDestroyed, (): SchedulesState => schedulesInitialState),
  on(
    apiSchedulesReceivedStatusUpdate,
    (state, action): SchedulesState => ({
      ...state,
      optimiserStatus: action.status,
      optimiserStarted: action.optimiserStarted
    })
  ),
  on(
    scheduleFetchedOnScheduleDetailsPage,
    (state, action): SchedulesState => ({
      ...state,
      optimiserStatus: action.schedule.status,
      optimiserStarted: action.schedule.optimiser_start_time
    })
  ),
  on(
    apiClearOptimiserSuccess,
    (state): SchedulesState => ({
      ...state,
      flyingLinesFilters: undefined
    })
  ),
  on(
    apiStopOptimiserSuccess,
    (state): SchedulesState => ({
      ...state,
      optimiserStatus: 'COMPLETE'
    })
  ),
  on(
    scheduleDetailsButtonStartOptimiserClicked,
    scheduleDetailsButtonResumeOptimiserClicked,
    (state): SchedulesState => ({
      ...state,
      optimiserStatus: 'STARTING'
    })
  ),
  on(
    apiStartOptimiserErrorsReturned,
    (state): SchedulesState => ({
      ...state,
      optimiserStatus: 'AVAILABLE'
    })
  ),
  on(apiLockSectorSuccess, (state, { line, sector }) => {
    const updatedLines = state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
      if (lineItem.schedule_flying_line_id === line.schedule_flying_line_id) {
        const updatedLine = { ...lineItem };
        updatedLine.sectors = updatedLine.sectors.map((sectorItem) => {
          if (sectorItem[0][11] === sector.id) {
            const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
            newSectorItem[0][13] = sector.dottedBorder ? 0 : 3;
            return newSectorItem;
          } else {
            return sectorItem;
          }
        });
        return updatedLine;
      }
      return lineItem;
    });

    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: updatedLines
      }
    };
  }),
  on(
    flyingLinesZoomButtonClicked,
    (state, action): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: { ...state.flyingLinesViewConfig, zoom: action.zoomValue }
    })
  ),
  on(
    flyingLinesShowCrewLinesButtonClicked,
    (state): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        showCrewLines: !state.flyingLinesViewConfig.showCrewLines
      }
    })
  ),
  on(
    flyingLinesShowAllOvernightGroundTimesButtonClicked,
    (state): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        showAllOvernightGroundTimes: !state.flyingLinesViewConfig.showAllOvernightGroundTimes
      }
    })
  ),
  on(
    flyingLinesUTCOffsetButtonClicked,
    (state, action): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        utcOffset: action.offset
      }
    })
  ),
  on(
    flyingLinesShowOnlyFrozenLinesButtonClicked,
    (state): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        showOnlyFrozenLines: !state.flyingLinesViewConfig.showOnlyFrozenLines
      }
    })
  ),
  on(flyingLinesTimeInButtonClicked, (state): SchedulesState => {
    const newTimeInValue = invertTimeLocale(state.flyingLinesViewConfig.timeIn);
    return {
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        timeIn: newTimeInValue
      }
    };
  }),
  on(
    flyingLinesColorSchemeButtonClicked,
    (state, action): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: {
        ...state.flyingLinesViewConfig,
        colorScheme: action.colorScheme
      }
    })
  ),
  on(
    flyingLinesPageClosed,
    (state): SchedulesState => ({
      ...state,
      flyingLinesViewConfig: { ...state.flyingLinesViewConfig, zoom: 1 }
    })
  ),
  on(
    flyingLinesApplyFiltersButtonClicked,
    (state, action): SchedulesState => ({
      ...state,
      flyingLinesFilters: action.flyingLinesFilters?.length ? action.flyingLinesFilters : undefined,
      flyingLinesViewConfig: {
        ...initialFlyingLinesViewConfig,
        zoom: state.flyingLinesViewConfig.zoom,
        colorScheme: state.flyingLinesViewConfig.colorScheme,
        timeIn: state.flyingLinesViewConfig.timeIn,
        daysSectorAverage: state.flyingLinesViewConfig.daysSectorAverage,
        isUpdateLinesEnabled: state.flyingLinesViewConfig.isUpdateLinesEnabled,
        isUpdateSectorsEnabled: state.flyingLinesViewConfig.isUpdateSectorsEnabled
      },
      dropdownFilterOperator: 'AND',
      dropdownFilterOptions: undefined
    })
  ),
  on(apiFreezeLineSuccess, apiUnfreezeLineSuccess, (state, action) => {
    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
          if (lineItem.schedule_flying_line_id === action.line.schedule_flying_line_id) {
            return {
              ...lineItem,
              frozen: action.type === apiFreezeLineSuccess.type,
              sectors: lineItem.sectors.map((sectorItem) => {
                const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
                newSectorItem[0][13] = action.type === apiFreezeLineSuccess.type ? 1 : 0;
                return newSectorItem;
              })
            };
          } else {
            return lineItem;
          }
        })
      }
    };
  }),
  on(apiBulkFreezeLineSuccess, apiBulkUnfreezeLineSuccess, (state, action) => {
    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
          if (action.lines.includes(lineItem.schedule_flying_line_id)) {
            return {
              ...lineItem,
              frozen: action.type === apiBulkFreezeLineSuccess.type,
              sectors: lineItem.sectors.map((sectorItem) => {
                const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
                newSectorItem[0][13] = action.type === apiBulkFreezeLineSuccess.type ? 1 : 0;
                return newSectorItem;
              })
            };
          } else {
            return lineItem;
          }
        })
      }
    };
  }),
  on(
    allAssociatedSectorSlotsAPIFetchSuccess,
    (state, action): SchedulesState => ({
      ...state,
      allAssociatedSectorSlots: action.allAssociatedSectorSlots
    })
  ),
  on(updateAssociatedSectorSlotsSuccess, (state, action): SchedulesState => {
    const associatedSlot: AssociatedSlot = action.associatedSlot;
    const slotType: 'DEPARTURE' | 'ARRIVAL' = action?.slotType;
    return {
      ...state,
      allAssociatedSectorSlots: {
        ...state.allAssociatedSectorSlots,
        departure_slots:
          slotType === 'DEPARTURE'
            ? [...(state.allAssociatedSectorSlots?.departure_slots ?? []), associatedSlot]
            : state.allAssociatedSectorSlots.departure_slots,
        arrival_slots:
          slotType === 'ARRIVAL'
            ? [...(state.allAssociatedSectorSlots?.arrival_slots ?? []), associatedSlot]
            : state.allAssociatedSectorSlots.arrival_slots
      }
    };
  }),
  on(updateAssociatedSectorSlotsSuccess, (state, action): SchedulesState => {
    const slotType: 'DEPARTURE' | 'ARRIVAL' = action?.slotType;
    const associatedSlot: AssociatedSlot = action.associatedSlot;
    return {
      ...state,
      optimiserResult: {
        ...state.optimiserResult,
        lines: state.optimiserResult.lines.map((lineItem: OptimiserResultLineDto) => {
          return lineItem.schedule_flying_line_id === action.lineId
            ? {
                ...lineItem,
                sectors: lineItem.sectors.map((sectorItem) => {
                  if (sectorItem[0][11] !== action.sectorId) {
                    return sectorItem;
                  }
                  const newSectorItem: OptimiserSectorDto = [[...sectorItem[0]], [...sectorItem[1]]];
                  if (slotType === 'DEPARTURE') {
                    newSectorItem[0][18] = action.departureSlotsId ?? associatedSlot.id;
                    newSectorItem[0][27] = 1;
                  } else if (slotType === 'ARRIVAL') {
                    newSectorItem[0][19] = action.arrivalSlotId ?? associatedSlot.id;
                    newSectorItem[0][28] = 1;
                  }
                  return newSectorItem;
                })
              }
            : lineItem;
        })
      }
    };
  }),
  on(
    scheduledCellDaysAPIFetchingSuccess,
    (state, action): SchedulesState => ({
      ...state,
      scheduledDays: action.scheduledDays
    })
  ),
  on(
    scheduleFetchedOptimiserObjectiveSuccess,
    (state, action): SchedulesState => ({
      ...state,
      scheduleInfoOptimiserObjective: action.scheduleOptimiserObjective
    })
  ),
  on(scheduleSettingsCapAPIFetchingSuccess, (state, action): SchedulesState => {
    return { ...state, scheduleSettingsCap: action.scheduleSettingsCap };
  }),
  on(apiScheduleSettingsSectorCapsAddedSuccess, (state, action): SchedulesState => {
    return { ...state, scheduleSettingsCap: [...(state.scheduleSettingsCap ?? []), action.scheduleSettingsCap] };
  }),
  on(apiScheduleSettingsSectorCapsUpdatedSuccess, (state, action): SchedulesState => {
    const updatedSectorCap = state.scheduleSettingsCap.map((sectorCap) =>
      action.scheduleSettingsCap.id === sectorCap.id ? action.scheduleSettingsCap : sectorCap
    );
    return { ...state, scheduleSettingsCap: updatedSectorCap };
  }),
  on(
    scheduleSettingsSectorCapsDeleted,
    (state, action): SchedulesState => ({
      ...state,
      scheduleSettingsCap: state.scheduleSettingsCap.filter((sectorCap) => sectorCap.id !== action.sectorCapId)
    })
  ),
  on(userloggedOutSuccess, (): SchedulesState => schedulesInitialState)
);
