import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { disableSeasonSelector, tokensRemovedInAnotherAppInStore, updateUserCredentials } from '@state/app.actions';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LogService } from '@shared/services/log.service';
import { StorageRecordName, StorageService } from '@shared/services/storage/storage.service';
import { ConnectionService } from '@shared/services/connection.service';
import { AuthorizationTokensDto } from '@authorization/services/auth-token.models';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'optifly-web-new';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private translate: TranslateService,
    private storageService: StorageService,
    private connectionService: ConnectionService
  ) {}

  ngOnInit(): void {
    this.connectionService.init();
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        switchMap((route: ActivatedRoute) => {
          // It should be fourth child, because this is fourth level of our routing
          // (AppModule, ComponentsModule and its children and then Feature Module (4), and it's children would be 5th level)
          return route.firstChild.firstChild?.firstChild?.firstChild?.data ?? of({});
        })
      )
      .subscribe((data) => {
        this.handleFeatureModuleData(data);
      });

    // Getting browser language and set language in app
    const lang = this.translate.getBrowserLang();
    switch (lang) {
      case 'en':
        this.translate.use('en');
        break;
      case 'de':
        this.translate.use('de');
        break;
      case 'es':
        this.translate.use('es');
        break;
      default:
        this.translate.use('en');
        break;
    }

    this.storageService.set(StorageRecordName.UserAccessTokensRefreshStarted, false).subscribe();
  }

  private listenToStorageChanges(): void {
    // Doesn't work yet, because we cannot observe changes from the other app, only from ours
    this.storageService
      .watch<AuthorizationTokensDto>(StorageRecordName.UserAccessTokens)
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((user) => {
        if (user) {
          LogService.log(
            'info',
            '[Storage] Received new tokens',
            user.access_token.slice(-10),
            user.refresh_token.slice(-10)
          );
          this.store.dispatch(updateUserCredentials({ loginResponseTokens: user }));
          if (window.location.pathname === '/sign-in') {
            this.router.navigate(['/schedules']);
          }
        } else {
          LogService.log('info', '[Storage] Tokens has been deleted');
          this.store.dispatch(tokensRemovedInAnotherAppInStore());
        }
      });
  }

  private handleFeatureModuleData(data: Data): void {
    const seasonSelectorDisabled: boolean = data?.seasonSelectorDisabled === true;
    this.store.dispatch(disableSeasonSelector({ seasonSelectorDisabled }));
  }
}
