import { createReducer, on } from '@ngrx/store';
import {
  airportScreenLoaded,
  apiLoadAirportsSuccess,
  apiAddAirportSuccess,
  apiUpdateAirportSuccess,
  apiUpdateBulkAirportsSuccess,
  apiDeleteAirportSuccess,
  apiLoadAirportsOptionsSuccess,
  airportsColumnsChanged,
  airportSelection
} from './airports.actions';
import { initialAirportsState, AirportsState } from './airports.state';
import { initialAirportsColumnsConfig } from '@features/settings/airports/airports-grid.config';

export const airportsReducers = createReducer<AirportsState>(
  initialAirportsState,

  on(airportScreenLoaded, (state): AirportsState => {
    return { ...state, settings: { ...state.settings, columnConfig: initialAirportsColumnsConfig } };
  }),

  on(apiLoadAirportsSuccess, (state, action): AirportsState => ({ ...state, list: action.airports })),

  on(apiAddAirportSuccess, (state, action): AirportsState => {
    const updatedAirports = [...state.list, action.airport].sort((a, b) => a.code.localeCompare(b.code));
    return { ...state, list: updatedAirports };
  }),

  on(apiUpdateAirportSuccess, (state, action): AirportsState => {
    const updatedAirports = state.list.map((airport) =>
      action.airport.code === airport.code ? action.airport : airport
    );
    return { ...state, list: updatedAirports };
  }),

  on(apiUpdateBulkAirportsSuccess, (state, action): AirportsState => {
    const updatedAirports = state.list.map((airport) => {
      const updatedAirport = action.airports.find((a) => a.code === airport.code);
      if (updatedAirport) {
        return updatedAirport;
      }
      return airport;
    });

    return { ...state, list: updatedAirports };
  }),

  on(apiDeleteAirportSuccess, (state, action): AirportsState => {
    const updatedAirports = state.list.filter((airport) => airport.code !== action.airportCode);
    return { ...state, list: updatedAirports };
  }),

  on(apiLoadAirportsOptionsSuccess, (state, action): AirportsState => ({ ...state, listOptions: action.listOptions })),

  on(airportsColumnsChanged, (state, action): AirportsState => {
    return { ...state, settings: { ...state.settings, columnConfig: action.columns } };
  }),

  on(airportSelection, (state, action): AirportsState => {
    return { ...state, settings: { ...state.settings, selectedAirports: action.selectedAirports } };
  })
);
