import { createReducer, on } from '@ngrx/store';
import { AircraftsState, initialState } from '@features/settings/aircrafts/state/aircrafts.state';
import {
  apiAddAircraftSuccess,
  apiDeleteAircraftSuccess,
  aircarftsAPIFetchingSuccess,
  apiUpdateAircraftSuccess
} from '@features/settings/aircrafts/state/aircrafts.actions';

export const aircraftsReducers = createReducer<AircraftsState>(
  initialState,
  on(aircarftsAPIFetchingSuccess, (state, action): AircraftsState => ({ ...state, aircrafts: action.aircrafts })),
  on(apiAddAircraftSuccess, (state, action): AircraftsState => {
    const updatedAircrafts = [...state.aircrafts, action.aircraft];
    return { ...state, aircrafts: updatedAircrafts };
  }),
  on(apiUpdateAircraftSuccess, (state, action): AircraftsState => {
    const updatedAircrafts = state.aircrafts.map((aircraft) =>
      action.aircraft.id === aircraft.id ? action.aircraft : aircraft
    );
    return { ...state, aircrafts: updatedAircrafts };
  }),
  on(apiDeleteAircraftSuccess, (state, action): AircraftsState => {
    const updatedAircrafts = state.aircrafts.filter((aircraft) => aircraft.id !== action.aircraftId);
    return { ...state, aircrafts: updatedAircrafts };
  })
);
