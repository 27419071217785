import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { AppState, initialState } from './app.state';
import {
  addingNewSchedulesAPISuccess,
  apiAddCategorySuccess,
  apiFavoriteScreenToggledSuccess,
  apiFetchCategoriesSuccess,
  apiGetUserDataSuccess,
  apiRemoveCategorySuccess,
  apiUpdateCategorySuccess,
  basesAPIFetchSuccess,
  basicSchedulerFullScreenOptionChanged,
  browserToggleFullscreen,
  disableSeasonSelector,
  flyingLinesFullScreenOptionChanged,
  internetConnectionStatusChanged,
  removeSchedulesClicked,
  scheduleDetailsPageDestroyed,
  scheduleFetchedOnScheduleDetailsPage,
  schedulesAPIFetchingSuccess,
  seasonChangedInRoute,
  seasonChangedInSelector,
  seasonChangedWhenNavigating,
  topRightMenuActionsLogoutSuccess,
  updateSchedulesAPISuccess,
  userloggedOutSuccess
} from './app.actions';
import * as Sentry from '@sentry/angular';
import {
  apiFlyingLinesCreateTargetScheduleSuccess,
  apiScheduleSettingsUpdateScheduleInfoFailed,
  apiScheduleSettingsUpdateScheduleInfoSuccess,
  scheduleSettingsHistoricSlotsRetimingChanged,
  scheduleSettingsOptimiserBranchChanged,
  scheduleSettingsOptimiserObjectiveChanged,
  scheduleSettingsOptimiserRuntimeMinutesChanged,
  scheduleSettingsProgressionMinsChanged,
  scheduleSettingsSlotOverridesCheckboxChanged,
  scheduleSettingsSlotProfileChanged
} from '@features/schedules/state/schedules.actions';
import { apiScheduleFetched, scheduleMainPageDestroyed } from '@features/schedules-new/state/schedules.actions';

type StoreReducer = { app: AppState; router: unknown };
function sentryBreadcrumbMetareducer(reducer: ActionReducer<StoreReducer>): ActionReducer<StoreReducer> {
  return function (state: StoreReducer, action: Action): StoreReducer {
    if (!action.type.startsWith('@ngrx')) {
      Sentry.addBreadcrumb({
        category: 'action',
        level: 'info',
        message: action.type,
        data: { ...action, type: undefined }
      });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<StoreReducer>[] = [sentryBreadcrumbMetareducer];

export const appReducers = createReducer<AppState>(
  localStorage.getItem('SeasonCode')
    ? {
        ...initialState,
        seasonCode: localStorage.getItem('SeasonCode')
      }
    : initialState,
  on(
    apiGetUserDataSuccess,
    (state, action): AppState => ({
      ...state,
      user: {
        ...action.user
      }
    })
  ),
  on(apiFavoriteScreenToggledSuccess, (state, action): AppState => {
    let newFavoriteScreens: string[];
    if (state.user.favorite_screens.includes(action.screen)) {
      newFavoriteScreens = state.user.favorite_screens.filter((screen) => screen !== action.screen);
    } else {
      newFavoriteScreens = [...state.user.favorite_screens, action.screen];
    }
    return {
      ...state,
      user: {
        ...state.user,
        favorite_screens: newFavoriteScreens
      }
    };
  }),
  on(
    topRightMenuActionsLogoutSuccess,
    (state): AppState => ({
      ...state,
      user: null
    })
  ),
  on(
    seasonChangedInRoute,
    seasonChangedInSelector,
    seasonChangedWhenNavigating,
    (state, action): AppState => ({ ...state, seasonCode: action.seasonCode, currentSchedule: null })
  ),
  on(
    disableSeasonSelector,
    (state, action): AppState => ({ ...state, seasonSelectorDisabled: action.seasonSelectorDisabled })
  ),
  on(
    scheduleFetchedOnScheduleDetailsPage,
    apiScheduleFetched,
    (state, action): AppState => ({ ...state, currentSchedule: action.schedule })
  ),
  on(
    scheduleSettingsSlotProfileChanged,
    scheduleSettingsSlotOverridesCheckboxChanged,
    scheduleSettingsHistoricSlotsRetimingChanged,
    scheduleSettingsProgressionMinsChanged,
    scheduleSettingsOptimiserRuntimeMinutesChanged,
    scheduleSettingsOptimiserObjectiveChanged,
    scheduleSettingsOptimiserBranchChanged,
    (state, action) => {
      // Store the previous state before updating
      const previousSchedule = state.currentSchedule;

      const updatedSchedule = { ...state.currentSchedule };

      switch (action.type) {
        case scheduleSettingsSlotProfileChanged.type:
          updatedSchedule.growth_slot_profile_id = action.value;
          break;
        case scheduleSettingsSlotOverridesCheckboxChanged.type:
          updatedSchedule[action.checkboxType] = action.value;
          if (action.checkboxType === 'allow_historic_slots_after_curfew' && !action.value) {
            updatedSchedule.historic_slots_after_curfew_can_be_retimed = false;
          }
          break;
        case scheduleSettingsHistoricSlotsRetimingChanged.type:
          updatedSchedule.historic_slots_retiming = action.value;
          break;
        case scheduleSettingsProgressionMinsChanged.type:
          updatedSchedule.min_max_progression_minutes = action.value;
          break;
        case scheduleSettingsOptimiserRuntimeMinutesChanged.type:
          updatedSchedule.optimiser_runtime_minutes = action.value;
          break;
        case scheduleSettingsOptimiserObjectiveChanged.type:
          updatedSchedule.optimiser_objective = action.value;
          break;
        case scheduleSettingsOptimiserBranchChanged.type:
          updatedSchedule.optimiser_branch = action.value;
          break;
      }

      return {
        ...state,
        currentSchedule: updatedSchedule,
        previousSchedule // Save the previous state in case we need to revert
      };
    }
  ),
  on(apiScheduleSettingsUpdateScheduleInfoSuccess, (state) => ({
    ...state,
    previousSchedule: undefined
  })),
  on(apiScheduleSettingsUpdateScheduleInfoFailed, (state) => ({
    ...state,
    currentSchedule: state.previousSchedule, // Revert to previous state on failure
    previousSchedule: undefined // Clear the previous state
  })),
  on(
    scheduleDetailsPageDestroyed,
    scheduleMainPageDestroyed,
    (state): AppState => ({ ...state, currentSchedule: null })
  ),
  on(apiFetchCategoriesSuccess, (state, action): AppState => ({ ...state, categories: action.categories })),
  on(
    apiAddCategorySuccess,
    (state, action): AppState => ({ ...state, categories: [...state.categories, action.category] })
  ),
  on(apiUpdateCategorySuccess, (state, action): AppState => {
    return {
      ...state,
      categories: state.categories
        .map((category) => (action.category.id === category.id ? action.category : category))
        .map((category) => {
          if (action.category.default) {
            return { ...category, default: category.id === action.category.id };
          } else {
            return category;
          }
        })
    };
  }),
  on(
    apiRemoveCategorySuccess,
    (state, action): AppState => ({
      ...state,
      categories: state.categories.filter((category) => category.id !== action.id)
    })
  ),

  // Schedules main page
  on(
    schedulesAPIFetchingSuccess,
    (state, { allSchedules }): AppState => ({ ...state, allSchedules: [...allSchedules] })
  ),
  on(addingNewSchedulesAPISuccess, apiFlyingLinesCreateTargetScheduleSuccess, (state, action): AppState => {
    return {
      ...state,
      allSchedules: [action.schedule, ...(state.allSchedules || [])]
    };
  }),
  on(updateSchedulesAPISuccess, (state, action): AppState => {
    const updatedSchedules = state.allSchedules.map((schedule) =>
      action.schedule.id === schedule.id ? action.schedule : schedule
    );
    return { ...state, allSchedules: updatedSchedules };
  }),
  on(removeSchedulesClicked, (state, action): AppState => {
    const updatedSchedules = state.allSchedules.filter((schedule) => schedule.id !== action.id);
    return { ...state, allSchedules: updatedSchedules };
  }),

  on(basesAPIFetchSuccess, (state, action): AppState => ({ ...state, bases: action.bases })),

  on(
    browserToggleFullscreen,
    flyingLinesFullScreenOptionChanged,
    basicSchedulerFullScreenOptionChanged,
    (state, action): AppState => {
      return { ...state, isFullscreen: action.isFullscreen };
    }
  ),
  on(internetConnectionStatusChanged, (state, action): AppState => {
    return { ...state, internetConnectionStatus: action.status };
  }),
  on(userloggedOutSuccess, (): AppState => initialState)
);
