import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import { settings } from '@utils/settings';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-key';

if (environment.production) {
  enableProdMode();
}

config({ licenseKey });

Sentry.init({
  dsn: 'https://7a2ef7ae146c434e8729aee4bd01a4ee@o1253252.ingest.sentry.io/6423957',
  enabled: environment.name !== 'local',
  integrations: [
    Sentry.browserTracingIntegration({
      // @ts-expect-error This is some type migration issue for angular 18
      tracePropagationTargets: [
        'https://production-elb.api.optifly.com',
        'https://java-api.app.optiflydev.com',
        'https://elb.api.optiflyuat.com',
        'https://elb.api.qa.optiflyuat.com'
      ]
      // Commented for now to migrate to angular 18
      // routingInstrumentation: Sentry.routingInstrumentation
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        'https://java-api.app.optiflydev.com',
        'https://elb.api.optiflyuat.com',
        'https://elb.api.qa.optiflyuat.com',
        'https://production-elb.api.optifly.com'
      ],
      networkCaptureBodies: true
    })
  ],
  environment: environment.name,
  autoSessionTracking: true,
  enableTracing: true,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

async function prepareApp(): Promise<void> {
  if (!settings.mockServiceWorker) return Promise.resolve();
  const { worker } = await import('./mocks/browser');
  worker.start({ quiet: !isDevMode(), onUnhandledRequest: 'bypass' });
  return Promise.resolve();
}

prepareApp().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
});
