import { Injectable } from '@angular/core';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';
import { Observable } from 'rxjs';
import { Category } from './categories.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
@ConfigEndpoint({ version: 2 })
export class CategoriesHttpService {
  rootEndPoint = 'categories';
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getCategories(seasonCode: SeasonCode): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.apiUrl}/${seasonCode}/${this.rootEndPoint}`);
  }

  addCategory(category: Category, seasonCode: SeasonCode): Observable<Category> {
    return this.http.post<Category>(`${this.apiUrl}/${seasonCode}/${this.rootEndPoint}`, category);
  }

  editCategory(category: Category, id: number, seasonCode: SeasonCode): Observable<Category> {
    return this.http.put<Category>(`${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${id}`, category);
  }

  removeCategory(id: number, seasonCode: SeasonCode): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${id}`);
  }
}
