import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@authorization/guards/auth.guard';

const routes: Routes = [
  {
    path: 'sign-in',
    loadChildren: async () => (await import('./features/auth/sign-in/sign-in.module')).SignInModule
  },
  {
    path: 'forgot-password',
    loadChildren: async () =>
      (await import('./features/auth/forgot-password/forgot-password.module')).ForgotPasswordModule
  },
  {
    path: '',
    loadChildren: async () => (await import('./features/components.module')).ComponentsModule,
    canActivate: [authGuard]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
