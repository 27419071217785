import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { selectSeason } from '@state/app.selectors';
import { userIsAuthenticated, seasonChangedInSelector } from '@state/app.actions';
import { AirportsHttpService } from '@features/settings/airports/airports.http.service';
import {
  apiLoadAirportsSuccess,
  apiLoadAirportsFailed,
  updateAirportButtonClicked,
  apiUpdateAirportSuccess,
  apiUpdateAirportFailed,
  addAirportButtonClicked,
  apiAddAirportSuccess,
  apiAddAirportFailed,
  deleteAirportButtonClicked,
  apiDeleteAirportSuccess,
  apiDeleteAirportFailed,
  apiLoadAirportsOptionsSuccess,
  apiLoadAirportsOptionsFailed,
  updateBulkAirportsButtonClicked,
  apiUpdateBulkAirportsSuccess,
  apiUpdateBulkAirportsFailed
} from './airports.actions';

@Injectable()
export class AirportsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private toastrService: ToastrService,
    private airportsService: AirportsHttpService
  ) {}

  fetchAirportsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(userIsAuthenticated, seasonChangedInSelector),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([_action, season]) => this.airportsService.getAllAirports(season)),
      map((airports) => apiLoadAirportsSuccess({ airports })),
      catchError(() => of(apiLoadAirportsFailed))
    );
  });

  addAirportEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(addAirportButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([{ airport }, season]) => this.airportsService.addAirport(airport, season)),
      tap(() => this.toastrService.success('Airport added successfully.')),
      map((airport) => apiAddAirportSuccess({ airport })),
      catchError(() => of(apiAddAirportFailed))
    );
  });

  updateAirportEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAirportButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([{ airport }, season]) => this.airportsService.updateAirport(airport, airport.code, season)),
      tap(() => this.toastrService.success('Airport updated successfully.')),
      map((airport) => apiUpdateAirportSuccess({ airport })),
      catchError(() => of(apiUpdateAirportFailed))
    );
  });

  bulkUpdateAirportsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateBulkAirportsButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([{ airports }, season]) => this.airportsService.bulkUpdateAirports(airports, season)),
      tap(() => this.toastrService.success('Airports bulk updated successfully.')),
      map((airports) => apiUpdateBulkAirportsSuccess({ airports })),
      catchError(() => of(apiUpdateBulkAirportsFailed))
    );
  });

  deleteAirportEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteAirportButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([{ airportCode }, season]) =>
        this.airportsService.deleteAirport(airportCode, season).pipe(
          tap(() => this.toastrService.success('Airport deleted successfully')),
          map(() => apiDeleteAirportSuccess({ airportCode })),
          catchError(() => of(apiDeleteAirportFailed))
        )
      )
    );
  });

  fetchAirportsOptionsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        userIsAuthenticated,
        seasonChangedInSelector,
        apiAddAirportSuccess,
        apiUpdateAirportSuccess,
        apiDeleteAirportSuccess
      ),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([_action, season]) => this.airportsService.getAirportOptions(season)),
      map((listOptions) => apiLoadAirportsOptionsSuccess({ listOptions })),
      catchError(() => of(apiLoadAirportsOptionsFailed))
    );
  });
}
