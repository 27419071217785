import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginRequestDto, AuthorizationTokensDto, LoginUserDataDto, RefreshTokenRequestDto } from './auth-token.models';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
@ConfigEndpoint({ version: undefined })
export class AuthHttpService {
  apiUrl: string;
  constructor(private http: HttpClient) {}

  login(loginRequestDto: LoginRequestDto): Observable<AuthorizationTokensDto> {
    return this.http.post<AuthorizationTokensDto>(`${this.apiUrl}/auth`, loginRequestDto);
  }

  refreshToken(refreshTokenRequestDto: RefreshTokenRequestDto): Observable<AuthorizationTokensDto> {
    return this.http.post<AuthorizationTokensDto>(`${this.apiUrl}/auth/refresh`, {
      refresh_token: refreshTokenRequestDto.refresh_token
    });
  }

  resetToken(userTokens: AuthorizationTokensDto): Observable<void> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${userTokens.access_token}`);
    const body = {
      refresh_token: userTokens.refresh_token
    };
    return this.http.post<void>(`${this.apiUrl}/auth/refresh/reset`, body, { headers: headers });
  }

  loadUser(): Observable<LoginUserDataDto> {
    return this.http.get<LoginUserDataDto>(`${environment.javaApiUrl}/v2/users/myself`);
  }
}
