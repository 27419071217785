import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { internetConnectionStatusChanged } from '@state/app.actions';

export type ConnectionStatus = 'connected' | 'disconnected' | 'unknown';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platform: NonNullable<unknown>,
    private store: Store
  ) {
    this.isBrowser = isPlatformBrowser(platform);
  }

  init(): void {
    if (!this.isBrowser) {
      return;
    }

    fromEvent(window, 'offline')
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(() => this.updateConnectionStatus('disconnected'));

    fromEvent(window, 'online')
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(() => this.updateConnectionStatus('connected'));

    this.updateConnectionStatus(navigator.onLine ? 'connected' : 'disconnected');
  }

  private updateConnectionStatus(status: ConnectionStatus): void {
    this.store.dispatch(internetConnectionStatusChanged({ status }));
  }
}
