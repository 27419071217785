import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  addingNewSchedulesAPISuccess,
  addingSchedulesAPIFailed,
  apiAddCategorySuccess,
  apiFavoriteScreenToggledSuccess,
  apiFetchCategoriesSuccess,
  apiRemoveCategorySuccess,
  apiUpdateCategorySuccess,
  userIsAuthenticated,
  basesAPIFetchSuccess,
  categoryAddedInCategoriesGrid,
  cloneSchedulesClicked,
  favoriteScreenToggled,
  removedCategoryInGrid,
  removeSchedulesClicked,
  removingSchedulesAPIFailed,
  removingSchedulesAPISuccess,
  schedulesAPIFetchingSuccess,
  seasonChangedInRoute,
  seasonChangedInSelector,
  updatedCategoryInGrid,
  updateSchedulesAPIFailed,
  updateSchedulesAPISuccess,
  updateSchedulesClicked,
  seasonChangedWhenNavigating
} from './app.actions';
import { CategoriesHttpService } from '@features/spreads/components/categories/categories.http.service';
import { Store } from '@ngrx/store';
import { selectCurrentUserId, selectSeason } from '@state/app.selectors';
import { EngineeringHttpService } from '@features/engineering/engineering.http.service';
import { ToastrService } from 'ngx-toastr';
import { SchedulesMainHttpService } from '@features/schedules/schedules-main/schedules-main.http.service';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { ModalService } from '@shared/components/dialogs/modal.service';
import { changeSchedulePrivacySuccess, schedulesListPageLoaded } from '@features/schedules/state/schedules.actions';
import {
  cloneToBasicSchedulerClicked,
  scheduleClonedToBasicScheduler
} from '@features/schedules-new/state/schedules.actions';
import { BasicSchedulerHttpService } from '@features/basic-scheduler/basic-scheduler.http.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { BASIC_SCHEDULER_URL } from '@features/components-routing.module';
import { daySpreadsFileImportedSuccess } from '@features/spreads/state/spreads.actions';
import { selectCurrentRouteState } from '@state/router.selector';
import { UsersHttpService } from '@features/users/services/users.http.service';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private usersHttpService: UsersHttpService,
    private categoriesHttpService: CategoriesHttpService,
    private engineeringService: EngineeringHttpService,
    private toastrService: ToastrService,
    private schedulesMainHttpService: SchedulesMainHttpService,
    private modalService: ModalService,
    private basicSchedulerHttpService: BasicSchedulerHttpService
  ) {}

  fetchCategoriesEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        userIsAuthenticated,
        seasonChangedInSelector,
        apiAddCategorySuccess,
        apiUpdateCategorySuccess,
        daySpreadsFileImportedSuccess,
        apiRemoveCategorySuccess
      ),
      concatLatestFrom(() => this.store.select(selectSeason)),
      mergeMap(([_action, season]) => this.categoriesHttpService.getCategories(season)),
      map((categories) => apiFetchCategoriesSuccess({ categories }))
    );
  });

  addCategoryEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryAddedInCategoriesGrid),
      concatLatestFrom(() => this.store.select(selectSeason)),
      concatMap(([action, seasonCode]) => this.categoriesHttpService.addCategory(action.category, seasonCode)),
      tap(() => this.toastrService.success('Category added successfully.')),
      map((category) => apiAddCategorySuccess({ category }))
    );
  });

  updateCategoryEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(updatedCategoryInGrid),
      concatLatestFrom(() => this.store.select(selectSeason)),
      mergeMap(([action, seasonCode]) =>
        this.categoriesHttpService.editCategory(action.category, action.id, seasonCode)
      ),
      tap(() => this.toastrService.success('Category updated successfully.')),
      map((category) => apiUpdateCategorySuccess({ category }))
    );
  });

  removeCategoryEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(removedCategoryInGrid),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, seasonCode]) => {
        return forkJoin([of(action), this.categoriesHttpService.removeCategory(action.id, seasonCode)]);
      }),
      tap(() => this.toastrService.success('Category deleted successfully.')),
      map(([action]) => {
        return apiRemoveCategorySuccess({ id: action.id });
      })
    );
  });

  fetchBasesEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(userIsAuthenticated, seasonChangedInSelector),
      concatLatestFrom(() => this.store.select(selectSeason)),
      mergeMap(([_action, season]) => {
        return this.engineeringService.getEngineeringAirports(season);
      }),
      map((bases) => {
        return basesAPIFetchSuccess({ bases });
      })
    );
  });

  updateSeasonInRouteEffect = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(seasonChangedInSelector),
        concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
        tap(([action, currentRoute]) => {
          // update season in :seasonCode route param if exists in route
          const newSeason = action.seasonCode;
          if (currentRoute?.params?.seasonCode) {
            const newRoute = currentRoute.url.replace(currentRoute.params.seasonCode, newSeason);
            this.router.navigate([newRoute], {
              queryParamsHandling: 'preserve'
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  saveSeasonEffect = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(seasonChangedInRoute, seasonChangedInSelector, seasonChangedWhenNavigating),
        tap((data) => {
          localStorage.setItem('SeasonCode', data.seasonCode);
        })
      );
    },
    { dispatch: false }
  );

  fetchSchedulesBySeasonEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(userIsAuthenticated, schedulesListPageLoaded, seasonChangedInSelector, changeSchedulePrivacySuccess),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([_action, season]) => this.schedulesMainHttpService.getAllSchedules(season)),
      map((allSchedules) => {
        return schedulesAPIFetchingSuccess({ allSchedules });
      })
    );
  });

  updatingSchedulesEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateSchedulesClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, season]) => this.schedulesMainHttpService.updateSchedule(action.schedule, season)),
      tap(() => this.toastrService.success('Schedule updated successfully.')),
      map((schedule) => updateSchedulesAPISuccess({ schedule })),
      catchError(() => of(updateSchedulesAPIFailed))
    );
  });

  removeSchedulesEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(removeSchedulesClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, season]) => this.schedulesMainHttpService.deleteSchedule(action.id, season)),
      tap(() => this.toastrService.success('Schedule removed successfully.')),
      map(() => removingSchedulesAPISuccess()),
      catchError(() => of(removingSchedulesAPIFailed))
    );
  });

  cloneSchedulesEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(cloneSchedulesClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      concatMap(([action, seasonCode]) => {
        this.modalService.showLoader('Cloning schedule...');
        return this.schedulesMainHttpService.cloneSchedule(action.id, action.name, seasonCode);
      }),
      map((schedule) => {
        this.modalService.hideLoader();
        this.toastrService.success('Schedule cloned successfully.');
        return addingNewSchedulesAPISuccess({ schedule });
      }),
      catchError(() => {
        this.modalService.hideLoader();
        this.toastrService.error('Failed to clone the Schedule.');
        return of(addingSchedulesAPIFailed);
      })
    );
  });

  cloneToBasicSchedulerEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(cloneToBasicSchedulerClicked),
      tap(() => this.modalService.showLoader('Cloning schedule')),
      concatMap((action) =>
        this.basicSchedulerHttpService
          .cloneFromSchedule(action.scheduleId)
          .pipe(finalize(() => this.modalService.hideLoader()))
      ),
      tap(() => this.toastrService.success('Schedule cloned to Basic Scheduler successfully.')),
      switchMap(() => fromPromise(this.router.navigate([BASIC_SCHEDULER_URL]))),
      map(() => scheduleClonedToBasicScheduler())
    );
  });

  favouritesInMenuAddedEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoriteScreenToggled),
      concatLatestFrom(() => this.store.select(selectCurrentUserId)),
      concatMap(([action, currentUserId]) =>
        forkJoin([this.usersHttpService.toggleFavoriteScreen(currentUserId, action.screen), of(action)])
      ),
      map(([, action]) => apiFavoriteScreenToggledSuccess({ screen: action.screen }))
    );
  });
}
