import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { concatMap, filter, Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthorizationTokensDto } from '@authorization/services/auth-token.models';
import { environment } from '@environments/environment';
import { isAccessTokenValid, isRefreshTokenValid } from '@utils/functions/token-validation.fun';
import { accessTokenNotFoundInStorage, refreshToken } from '@state/app.actions';
import { LogService } from '@shared/services/log.service';
import { StorageRecordName, StorageService } from '@shared/services/storage/storage.service';
import { backoffRetry } from '@shared/helpers/backoff-operator';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private requestId = 0;

  private refreshTokenStarted = false;

  constructor(
    private store: Store,
    private router: Router,
    private storageService: StorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const ignoredUrls = ['auth', 'auth/refresh', 'ngsw.json', 'assets/i18n'];
    if (ignoredUrls.some((url) => request.url.includes(url))) return next.handle(request);

    this.requestId++;
    const localRequestId = this.requestId;

    return this.storageService.get<AuthorizationTokensDto>(StorageRecordName.UserAccessTokens).pipe(
      tap((tokens) => {
        if (!tokens) {
          this.store.dispatch(accessTokenNotFoundInStorage());
        }
      }),
      filter((tokens) => !!tokens),
      tap((tokens) => {
        if (this.refreshTokenStarted) {
          LogService.log('info', `[Interceptor:${localRequestId}] retrying request `, request.url);
        }
        if (isAccessTokenValid(tokens)) return;

        if (!this.refreshTokenStarted && isRefreshTokenValid(tokens)) {
          this.refreshTokenStarted = true;
          LogService.log('info', `[Interceptor:${localRequestId}] dispatching refreshToken action for `, request.url);
          this.store.dispatch(refreshToken({ authenticationResult: tokens, requestId: localRequestId }));
          throw Error(`Refreshing token, retrying request ${localRequestId} in a while`);
        } else if (this.refreshTokenStarted) {
          throw Error(`Waiting for token to request ${localRequestId} to be refreshed...`);
        } else if (!isRefreshTokenValid(tokens)) {
          LogService.log('info', `[Interceptor:${localRequestId}] refresh token is not valid, ending`);
          this.router.navigate(['/sign-in']);
        }
      }),
      backoffRetry({ delay: 1500, count: 3 }),
      tap(() => (this.refreshTokenStarted = false)),
      filter((tokens) => isAccessTokenValid(tokens)),
      concatMap((tokens) => {
        const isApiUrl = request.url.startsWith(environment.javaApiUrl);
        const authReq = isApiUrl ? this.addRequestHeaders(request, tokens) : request;
        return next.handle(authReq);
      }),
      catchError((error) => {
        this.refreshTokenStarted = false;
        throw error;
      })
    );
  }

  private addRequestHeaders(
    request: HttpRequest<unknown>,
    authenticationResult: AuthorizationTokensDto | undefined
  ): HttpRequest<unknown> {
    const headers: { [name: string]: string } = {
      ReferrerPolicy: 'no-referrer',
      AccessControlAllowOrigin: '*'
    };
    if (authenticationResult) {
      headers['Authorization'] = `Bearer ${authenticationResult.access_token}`;
    }
    return request.clone({
      setHeaders: headers
    });
  }
}
