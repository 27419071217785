import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { appStarted } from '@state/app.actions';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(private store: Store) {
    this.store.dispatch(appStarted());
  }
}
