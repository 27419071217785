import { AuthorizationTokensDto } from '@authorization/services/auth-token.models';
import { DateTime } from 'ts-luxon';

export function isAccessTokenValid(authenticationResult: AuthorizationTokensDto): boolean {
  if (!authenticationResult?.expires_in) return false;
  const expirationTime = DateTime.fromMillis(authenticationResult.expires_in);
  return expirationTime > DateTime.now();
}

export function isRefreshTokenValid(authenticationResult: AuthorizationTokensDto): boolean {
  if (!authenticationResult?.refresh_expires_in) return false;
  const now = DateTime.now();
  const refreshTokenExpirationTime = DateTime.fromMillis(authenticationResult.refresh_expires_in);
  return now < refreshTokenExpirationTime;
}

export function shouldTokenBeRenewed(authenticationResult: AuthorizationTokensDto): boolean {
  if (!authenticationResult?.expires_in || !authenticationResult?.refresh_expires_in) return false;
  return !isAccessTokenValid(authenticationResult) && isRefreshTokenValid(authenticationResult);
}
