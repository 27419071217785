import { createReducer, on } from '@ngrx/store';
import {
  addingNewAOCSuccess,
  aocAPIFetchingSuccess,
  removingAOCSuccess,
  updatingAOCSuccess
} from '@features/settings/airline-operating-certificate/state/airline-operating-certificate.actions';
import {
  AirlineOperatingCertificateState,
  initialState
} from '@features/settings/airline-operating-certificate/state/airline-operating-certificate.state';

export const airlineOperatingCertificatesReducers = createReducer<AirlineOperatingCertificateState>(
  initialState,
  on(
    aocAPIFetchingSuccess,
    (state, action): AirlineOperatingCertificateState => ({
      ...state,
      airlineOperatingCertificates: action.aocs
    })
  ),
  on(
    addingNewAOCSuccess,
    (state, action): AirlineOperatingCertificateState => ({
      ...state,
      airlineOperatingCertificates: [...state.airlineOperatingCertificates, action.aoc]
    })
  ),
  on(updatingAOCSuccess, (state, action): AirlineOperatingCertificateState => {
    const updatedAOCs = state.airlineOperatingCertificates.map((aoc) => (action.aoc.id === aoc.id ? action.aoc : aoc));
    return { ...state, airlineOperatingCertificates: updatedAOCs };
  }),
  on(removingAOCSuccess, (state, action): AirlineOperatingCertificateState => {
    const updatedAOCs = state.airlineOperatingCertificates.filter((aoc) => aoc.id !== action.id);
    return { ...state, airlineOperatingCertificates: updatedAOCs };
  })
);
